<template>
  <footer class="c-site-footer flex flex-row flex-wrap items-center">
    <p class="c-site-footer__copyright m-0">
      &copy; {{ new Date().getFullYear() }} Saul Hardman
    </p>

    <ul
      class="c-site-footer__link-list list-none flex items-center m-0 mt-3 -ml-2 alpha:my-0 alpha:ml-auto p-0 w-full alpha:w-auto"
    >
      <li
        v-for="({ href, label, title }, index) in links"
        :key="index"
        class="pl-2 first:pl-0"
      >
        <viewsource-link :href="href" :title="title" class="px-2">{{
          label
        }}</viewsource-link>
      </li>

      <li class="pl-2">
        <a
          class="-mr-3 p-3 no-underline inline-block text-4 text-current-color"
          href="https://webring.coolkidscomputer.club"
          title="Traverse the CKCC Indie Webring"
        >
          &#x267B;&#xFE0E;
          <span class="sr-only">Traverse the CKCC Indie Webring</span>
        </a>
      </li>
    </ul>
  </footer>
</template>

<script>
import ViewsourceLink from '~/components/ViewsourceLink.vue';

export default {
  components: {
    ViewsourceLink,
  },

  data() {
    return {
      links: [
        {
          href: 'https://dev.to/saul',
          label: 'DEV',
          title: 'Follow Saul on the DEV community',
        },

        {
          href: 'https://twitter.com/viewsourceio',
          label: 'Twitter',
          title: 'Follow @viewsourceio on Twitter',
        },

        {
          href: '/feed.xml',
          label: 'RSS',
          title: 'Subsribe to the RSS Feed',
        },
      ],
    };
  },
};
</script>
